@tailwind base;
* {
  --main: #5b0f8d;
  --secondary-dark: #3b086b;
  --secondary-light: #a594b5;
  --light-gray: #f2f2f2;
  --basic-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  /* // color: var(--main); */

  /* // TODO: to delete:
    ////////////////////////////////////////////////// */
  --dark-blue: #1e0535;
  /* // --dark-blue: #131a27; */
  --dark-blue2: rgb(18, 32, 52, 1);
  --darkest-blue: #121722;
  --light-skin: #e8c49e;
  --dark-purple: #4f1087;
  /* ////////////////////////////////////////////////// */
}

a {
  text-decoration: none;
}

html {
  /* // min-width: 920px; */
  scroll-behavior: smooth;
  -webkit-scroll-behavior: smooth;
}

ul {
  list-style: none;
}

table {
  border-spacing: 0ch;
  position: relative;
  z-index: 1;
}

p,
span,
i {
  font-size: inherit;
  color: inherit;
  font-family: inherit;
}

button {
  border: none;
  background-color: transparent;
}

#root > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  min-height: 100vh;
}

.deco-dash {
  position: absolute;
  top: 20px;
  left: -150px;
  width: 50vw;
  height: 40px;
  background-color: rgba(215, 215, 215, 0.15);
}

.desc {
  @apply text-4xl sm:text-5xl text-gray-300/80 mt-4;
}

@layer base {
  h1 {
    @apply text-5xl mt-10 font-extralight font-poster2 text-gray-400;
  }

  h2 {
    @apply font-sans font-bold text-main text-3xl;
  }

  h3 {
    @apply font-sans text-secondary-dark/90 font-medium text-2xl;
  }

  p {
    @apply font-main-serif;
  }
}
